import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const VentesMatelasProductSectionWrapper = styled.div`
  width: 100%;
  position: relative;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1900px;
  padding: 20px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 10px 10px 20px;
  }
`

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 0px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`

const ProductBox = styled.div`
  width: 100%;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  color: #262626;

  :last-of-type {
    margin: 10px 0px 10px 0px;

    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  :nth-of-type(2) {
    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  @media (max-width: 767px) {
    margin: 5px 10px 5px 0px;
    flex-basis: calc(50% - 5px);
  }
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const ButtonTitle = styled.span`
  display: none;
`

const ImageBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`

const HiddenImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  visibility: hidden;
  opacity: 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
`

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  div {
    position: relative;
  }

  iframe,
  video {
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;
  }
`

const ImageText = styled.div`
  background: #fff;
  color: #262626;
  font-size: 12px;
  line-height: 1;
  font-family: "Gotham";
  font-weight: bold;
  position: absolute;
  bottom: 15px;
  right: 15px;
  max-width: calc(100% - 30px);
  margin: 0px;
  padding: 7px 10px 5px;
  border-radius: 5px;

  span {
    display: block;
  }

  @media (max-width: 380px) {
    padding: 6px 7px 5px;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0px 0px;
`

const Title = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
`

const DiscountText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  color: ${props => props.theme.saleColor.textColor};
  background: ${props => props.theme.saleColor.bgColor};
  padding: 5px 5px 2px;
  // color: ${props => props.theme.saleColor.bgColor};
`

const CustomBox = styled.div`
  width: 100%;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background: #e7f0f3;
  color: #262626;

  :last-of-type {
    margin: 10px 0px 10px 0px;

    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  :nth-of-type(2) {
    @media (max-width: 767px) {
      margin: 5px 0px 5px 0px;
    }
  }

  @media (max-width: 767px) {
    margin: 5px 10px 5px 0px;
    flex-basis: calc(50% - 5px);
  }
`

const CustomBoxWrapper = styled.div`
  height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #262626;
  padding: 10px;
  margin: 10px;

  @media (max-width: 480px) {
    height: calc(100% - 10px);
    padding: 5px;
    margin: 5px;
  }
`

const CustomTitle = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  span {
    display: block;
  }

  @media (max-width: 1600px) {
    font-size: 22px;
  }

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 140px;
  margin: 40px 0px;
  object-fit: contain;

  @media (max-width: 1600px) {
    max-width: 120px;
    margin: 15px 0px;
  }

  @media (max-width: 1350px) {
    max-width: 80px;
  }

  @media (max-width: 992px) {
    max-width: 75px;
    margin: 10px 0px;
  }

  @media (max-width: 420px) {
    max-width: 60px;
    margin: 5px 0px;
  }
`

const CustomText = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 992px) {
    font-size: 12px;
  }
`

const BottomText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  margin: 10px 0px 0px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`

const VentesMatelasProductSection = ({ intl, data }) => {
  return (
    data && (
      <VentesMatelasProductSectionWrapper>
        <VentesContainer>
          {data.list && (
            <RowWrapper>
              <CustomBox>
                {data.url && (
                  <AbsoluteButton to={data.url}>
                    <ButtonTitle>Découvrir</ButtonTitle>
                  </AbsoluteButton>
                )}
                <HiddenImage
                  className="lazyload"
                  data-src="https://static.percko.com/uploads/453e44a9-7d87-43ca-8475-504816153eb9.jpg"
                  alt=""
                />
                <CustomBoxWrapper>
                  {data.title && (
                    <CustomTitle
                      dangerouslySetInnerHTML={{
                        __html: data.title,
                      }}
                    />
                  )}
                  {data.logo && (
                    <LogoImage
                      className="lazyload"
                      data-src={data.logo}
                      alt={data.alt ? data.alt : ""}
                    />
                  )}
                  {data.text && (
                    <CustomText
                      dangerouslySetInnerHTML={{
                        __html: data.text,
                      }}
                    />
                  )}
                </CustomBoxWrapper>
              </CustomBox>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <ProductBox key={index.toString()}>
                      {item.url && (
                        <AbsoluteButton to={item.url}>
                          <ButtonTitle>Découvrir</ButtonTitle>
                        </AbsoluteButton>
                      )}
                      <ImageBox>
                        {item.image && (
                          <Image
                            className="lazyload"
                            data-src={item.image}
                            alt={item.alt ? item.alt : ""}
                          />
                        )}
                        {index === 1 && (
                          <VideoWrapper>
                            <ReactPlayer
                              url={
                                "https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_new_video_2_surmatelas.mp4"
                              }
                              playing={true}
                              controls={false}
                              muted={true}
                              loop={true}
                              width="100%"
                              height="100%"
                              playsinline={true}
                              playsInline
                            />
                          </VideoWrapper>
                        )}
                        {item.text && (
                          <ImageText
                            dangerouslySetInnerHTML={{
                              __html: item.text,
                            }}
                          />
                        )}
                      </ImageBox>
                      <TextWrapper>
                        {item.title && (
                          <Title
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                        )}
                        {item.discount_text && (
                          <DiscountText
                            dangerouslySetInnerHTML={{
                              __html: item.discount_text,
                            }}
                          />
                        )}
                      </TextWrapper>
                    </ProductBox>
                  )
                )
              })}
            </RowWrapper>
          )}
          {data.bottom_text && (
            <BottomText
              dangerouslySetInnerHTML={{
                __html: data.bottom_text,
              }}
            />
          )}
        </VentesContainer>
      </VentesMatelasProductSectionWrapper>
    )
  )
}

export default injectIntl(VentesMatelasProductSection)
